import { FormLabel, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import debounceAsync from "awesome-debounce-promise";
import { UseFormProps } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormFieldControl,
  FormProps,
  InputFormField,
  useForm,
} from "@/components/form";
import { ProxyService } from "@/services";
import { isUrl } from "@/utils";

export interface LinkAccountFormProps extends FormProps {}

export const LinkAccountForm = (props: LinkAccountFormProps) => {
  return (
    <Form {...props}>
      <VStack spacing={4}>
        <FormFieldControl name="emailAddress">
          <FormLabel>Email address</FormLabel>
          <InputFormField />
        </FormFieldControl>

        <FormFieldControl name="password">
          <FormLabel>Password</FormLabel>
          <InputFormField type="password" />
        </FormFieldControl>

        <FormFieldControl name="proxy">
          <FormLabel>Proxy</FormLabel>
          <InputFormField />
        </FormFieldControl>
      </VStack>
    </Form>
  );
};

export const useLinkAccountForm = (
  options: UseFormProps<LinkAccountFieldValues> = {}
) => {
  const methods = useForm<LinkAccountFieldValues>({
    defaultValues: {
      emailAddress: "",
      password: "",
      proxy: "",
    },
    resolver: zodResolver(schema, { async: true }, { mode: "async" }),
    ...options,
  });

  return methods;
};

export interface LinkAccountFieldValues {
  emailAddress: string;
  password: string;
  proxy: string;
}

const verifyProxy = debounceAsync(ProxyService.verify, 500);

const schema = z.object({
  emailAddress: z
    .string()
    .email("The value must be a valid email address")
    .min(1, "This value is required"),
  password: z.string().min(4, "The password must be at least 4 characters"),
  proxy: z
    .string()
    .min(1, "This value is required")
    // .refine(
    //   async (value) => {
    //     if (!isUrl(value)) {
    //       return false;
    //     }

    //     try {
    //       const verification = await verifyProxy({ url: value });
    //       return verification!.isValid;
    //     } catch {
    //       return false;
    //     }
    //   },
    //   {
    //     message: "The proxy is invalid or unreachable",
    //   }
    // )
    ,
});
